/** @jsx jsx */
import React, { type ReactNode } from 'react';
import { jsx, cssMap } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Link from '@atlassian/jira-common-analytics-v2-wrapped-components/src/link.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';

type Props = {
	avatarUrl: string | undefined;
	displayName: string;
	href: string | undefined;
	appearance: 'square' | 'circle';
	// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
	onClick: () => void | null | undefined;
};

const styles = cssMap({
	avatarContainer: {
		paddingRight: token('space.100', '8px'),
		lineHeight: 0,
		flex: '0 0 auto',
	},
	container: {
		paddingTop: token('space.075', '6px'),
		paddingBottom: token('space.075', '6px'),
		display: 'flex',
		flex: '1 1 auto',
	},
	contentContainer: {
		display: 'flex',
		alignItems: 'center',
		flex: '1 1 auto',
		width: '100%',
	},
	accessibleContentContainer: {
		paddingTop: token('space.100'),
		paddingBottom: token('space.100'),
		display: 'flex',
		alignItems: 'center',
		flex: '1 1 auto',
		width: '100%',
	},
	link: {
		width: '100%',
	},
	textContainer: {
		wordWrap: 'break-word',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
});

export default function AvatarTitleCell(props: Props) {
	const { avatarUrl, displayName, href, appearance, onClick } = props;

	const content = (
		<Content>
			<div css={styles.avatarContainer}>
				<Avatar src={avatarUrl} size="small" appearance={appearance} />
			</div>
			<div css={styles.textContainer}>{displayName}</div>
		</Content>
	);

	if (href) {
		return (
			<div css={styles.container}>
				<div css={styles.link}>
					<Link href={href} onClick={onClick}>
						{content}
					</Link>
				</div>
			</div>
		);
	}
	return <div css={styles.contentContainer}>{content}</div>;
}

AvatarTitleCell.defaultProps = {
	avatarUrl: undefined,
	href: undefined,
	appearance: 'circle',
	onClick: undefined,
};

const accessibleContent = (props: { children: ReactNode }) => {
	return isVisualRefreshEnabled() && fg('visual-refresh_drop_2') ? (
		<Box xcss={accessibleContentContainerStyles}>{props.children}</Box>
	) : (
		<div css={styles.accessibleContentContainer}>{props.children}</div>
	);
};

const inaccessibleContent = (props: { children: ReactNode }) => (
	<div css={styles.contentContainer}>{props.children}</div>
);

const Content = componentWithFG(
	'blu-4174-enghealth-11799-a11y-fix',
	accessibleContent,
	inaccessibleContent,
);

const accessibleContentContainerStyles = xcss({
	paddingTop: 'space.100',
	paddingBottom: 'space.100',
	display: 'flex',
	alignItems: 'center',
	flex: '1 1 auto',
	width: '100%',
	height: '40px',
});
