import React, { Component, type ComponentType } from 'react';
import { styled as styled2 } from '@compiled/react';
import noop from 'lodash/noop';
import generateUniqId from 'uuid/v1';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
// @ts-expect-error - TS2614 - Module '"@atlaskit/dynamic-table"' has no exported member 'HeadCellType'. Did you mean to use 'import HeadCellType from "@atlaskit/dynamic-table"' instead? | TS2614 - Module '"@atlaskit/dynamic-table"' has no exported member 'HeadType'. Did you mean to use 'import HeadType from "@atlaskit/dynamic-table"' instead?
import { DynamicTableStateless, type HeadCellType, type HeadType } from '@atlaskit/dynamic-table';
import { SpotlightTarget } from '@atlaskit/onboarding';
import { Flex, xcss, Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { AnnouncerV2 } from '@atlassian/jira-accessibility/src/ui/announcer-v2/index.tsx';
import { ASC } from '@atlassian/jira-common-constants/src/sort-directions.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import MountReporter from '../../../common/page-ready/mount-reporter/index.tsx';
import type {
	SpotlightCellTarget,
	OperationParams,
	ColumnAlignment,
} from '../../../model/index.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

// eslint-disable-next-line jira/react/no-class-components
class Table<
	TOperation,
	TSortField extends string,
	TChildEntityId = string,
	TEntity = undefined,
> extends Component<Props<TOperation, TSortField, TChildEntityId, TEntity>> {
	static defaultProps = {
		isLoading: false,
		entityIds: [],
		totalItems: 0,
		sortDirection: ASC,
		mountMetricKey: undefined,
		getSpotlightForCell: noop,
		TableMaskView: undefined,
		firstColumnHeadingAlignment: 'left',
		lastColumnHeadingAlignment: 'left',
		filterQuery: '',
		tableLabel: undefined,
		resultsAnnouncerMessage: messages.filterResultsAnnouncement,
		onSort: noop,
		onOperationRequested: noop,
	};

	state = {
		hasSortingChanged: false,
		shouldAnnounceResults: false,
	};

	componentDidUpdate(prevProps: Readonly<Props<TOperation, TSortField, TChildEntityId, TEntity>>) {
		if (
			prevProps.sortDirection !== this.props.sortDirection ||
			prevProps.sortField !== this.props.sortField
		) {
			this.state.hasSortingChanged = true;
		}

		if (!prevProps.isLoading && this.props.isLoading) {
			this.state.shouldAnnounceResults = !this.state.hasSortingChanged;
		} else if (prevProps.isLoading && !this.props.isLoading) {
			this.state.hasSortingChanged = false;
		}
	}

	/**
	 * We very much wish that `attrs` is an object of this type {| key: string, sortOrder: string |}
	 * but unfortunately @atlaskit/dynamic-table types do not give any guarantee of that
	 * so we should be handling mixed here.
	 */
	onSort = (attrs: unknown, analyticsEvent: undefined | UIAnalyticsEvent) => {
		// @ts-expect-error - TS2339 - Property 'key' does not exist on type 'unknown'. | TS2339 - Property 'sortOrder' does not exist on type 'unknown'.
		const { key, sortOrder } = attrs;
		const { onSort } = this.props;

		if (analyticsEvent) {
			fireUIAnalytics(analyticsEvent, 'tableHeader', { key });
		}

		onSort({
			sortField: key,
			sortDirection: sortOrder,
		});
	};

	render() {
		const {
			tableConfiguration,
			entityIds,
			totalItems,
			EmptyView,
			isLoading,
			operationInitializationStatusPerEntity,
			sortField,
			sortDirection,
			mountMetricKey,
			getSpotlightForCell,
			TableMaskView,
			dataProvider,
			firstColumnHeadingAlignment,
			lastColumnHeadingAlignment,
			intl: { formatMessage },
			tableLabel,
			resultsAnnouncerMessage,
		} = this.props;

		const headCells: HeadCellType[] = tableConfiguration.map(
			({ title, width, sortField: cellSortField, dataTestId }): HeadCellType => {
				let content = '';
				const wrapperProps = dataTestId ? { 'data-test-id': dataTestId } : {};

				if (dataTestId) {
					// @ts-expect-error - TS2322 - Type 'Element' is not assignable to type 'string'.
					content = <NonBreakingCell {...wrapperProps} />;
				}

				if (typeof title === 'function') {
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					const HeaderCell: ComponentType<Record<any, any>> = title;
					// @ts-expect-error - TS2322 - Type 'Element' is not assignable to type 'string'.
					content = (
						<HeaderWrapper {...wrapperProps}>
							<HeaderCell />
						</HeaderWrapper>
					);
				} else if (typeof title !== 'undefined') {
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
					const message: MessageDescriptor = title as any;

					// @ts-expect-error - TS2322 - Type 'Element' is not assignable to type 'string'.
					content =
						isVisualRefreshEnabled() && fg('visual-refresh_drop_3') ? (
							<Flex>
								<Text
									testId={dataTestId}
									color="color.text.subtle"
									maxLines={1}
									weight="bold"
									size="small"
								>
									{formatMessage(message)}
								</Text>
							</Flex>
						) : (
							<NonBreakingCell {...wrapperProps}>{formatMessage(message)}</NonBreakingCell>
						);
				}
				return {
					key: cellSortField || generateUniqId(),
					isSortable: !!cellSortField,
					content,
					width,
				};
			},
		);
		const head: HeadType = { cells: headCells };

		const ids = dataProvider
			? dataProvider.getData().map((entity) => dataProvider.idSelector(entity))
			: entityIds;

		const tableAriaLabel = tableLabel ? formatMessage(tableLabel) : undefined;

		const rowCells = ids.map((entityId, rowIndex) => {
			// the page is already sorted (by the API), so fake key,
			// so that DynamicTable won't destroy it
			const key = sortDirection === 'DESC' ? -(rowIndex + 1) : rowIndex + 1;
			const entity = dataProvider ? dataProvider.getData()[rowIndex] : undefined;
			const cells = tableConfiguration.map(({ Cell }, colIndex) => {
				const content = (
					<Flex
						alignItems="center"
						xcss={
							isVisualRefreshEnabled() && fg('visual-refresh_drop_2')
								? cellWrapperStyles
								: undefined
						}
					>
						<Cell
							id={entityId}
							entity={entity}
							isOperationInProgress={!!operationInitializationStatusPerEntity[entityId]}
							// eslint-disable-next-line jira/react-no-inline-function-prop
							onOperationRequested={(
								operation: TOperation,
								operationParams?: OperationParams<TChildEntityId>,
							) =>
								this.props.onOperationRequested({
									id: entityId,
									childEntityId: operationParams && operationParams.childEntityId,
									operation,
								})
							}
						/>
					</Flex>
				);

				const cellMatch: SpotlightCellTarget = getSpotlightForCell(entityId, colIndex);

				if (cellMatch && cellMatch.spotlightId) {
					const onboardingContent = (
						<SpotlightTarget name={cellMatch.spotlightId}>{content}</SpotlightTarget>
					);
					return {
						content: onboardingContent,
						key,
					};
				}

				return {
					content,
					key,
				};
			});
			return { cells };
		});

		return (
			// Note: the data-test-id attribute is used by Pollinator checks in Jalapeno so avoid changing if possible
			<Container
				data-test-id="global-pages.directories.directory-base.content.table.container"
				firstColumnHeadingAlignment={firstColumnHeadingAlignment}
				lastColumnHeadingAlignment={lastColumnHeadingAlignment}
				visualRefreshEnabled={isVisualRefreshEnabled() && fg('visual-refresh_drop_2')}
			>
				{!isLoading && <MountReporter performanceMarkKey={mountMetricKey} />}
				<AnnouncerV2
					message={formatMessage(resultsAnnouncerMessage || messages.filterResultsAnnouncement, {
						itemCount: totalItems,
					})}
					shouldAnnounce={!isLoading && this.state.shouldAnnounceResults}
				/>
				<DynamicTableStateless
					isFixedSize
					isLoading={isLoading}
					head={head}
					rows={rowCells}
					onSort={this.onSort}
					sortKey={sortField}
					sortOrder={sortDirection}
					emptyView={isLoading ? <EmptyLoadingView /> : <EmptyView />}
					label={tableAriaLabel}
				/>
				{TableMaskView && <TableMaskView />}
			</Container>
		);
	}
}

// @ts-expect-error - Argument of type 'typeof Table' is not assignable to parameter of type 'ComponentType<WithIntlProvided<Props<unknown, string, unknown, unknown>>> & typeof Table'.
export default injectIntl(Table);

const TABLE_ROW_HEIGHT = '40px';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NonBreakingCell = styled2.span({
	whiteSpace: 'nowrap',
});

const cellWrapperStyles = xcss({
	height: '40px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled2.div<{
	firstColumnHeadingAlignment: ColumnAlignment;
	lastColumnHeadingAlignment: ColumnAlignment;
	visualRefreshEnabled?: boolean;
}>(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) => ({
		position: 'relative',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& tbody': {
			verticalAlign: 'top',
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& td': {
			paddingTop: 0,
			paddingBottom: 0,
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		'& th:first-of-type': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			textAlign: props.firstColumnHeadingAlignment,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			svg: {
				height: '14px',
				width: '14px',
			},
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		'& th:last-of-type': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			textAlign: props.lastColumnHeadingAlignment,
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& th': {
			verticalAlign: 'middle',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			span: {
				whiteSpace: 'normal',
			},
		},
	}),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	({ visualRefreshEnabled }) =>
		visualRefreshEnabled && {
			'& table': {
				borderWidth: '1px',
				borderStyle: 'solid',
				borderColor: token('color.border'),
				borderRadius: '6px',
			},
			'& th': {
				font: token('font.heading.xxsmall'),
				backgroundColor: token('elevation.surface.sunken'),
				height: TABLE_ROW_HEIGHT,
			},
			'& th:first-of-type': {
				borderTopLeftRadius: '6px',
			},
			'& th:last-of-type': {
				borderTopRightRadius: '6px',
			},
		},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmptyLoadingView = styled2.div({
	height: '150px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderWrapper = styled2.div({
	display: 'inline-block',
});
