import React, { type ReactNode, type PointerEvent } from 'react';
import { styled } from '@compiled/react';
import { Box, Flex, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Link from '@atlassian/jira-common-analytics-v2-wrapped-components/src/link.tsx';
import type { AnalyticsEvent } from '@atlassian/jira-common-analytics-v2-wrapped-components/src/types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';

type Props = {
	caption: ReactNode;
	href: string;
	onClick: (event: PointerEvent, analyticsEvent: AnalyticsEvent) => void;
};

export default function LinkCell({ caption, href, onClick, ...props }: Props) {
	const content = href ? (
		<Link href={href} onClick={onClick}>
			{caption}
		</Link>
	) : (
		caption
	);

	return isVisualRefreshEnabled() && fg('visual-refresh_drop_2') ? (
		<Flex wrap="nowrap" direction="row" xcss={containerStyles}>
			<Box xcss={wrapperStyles}>{content}</Box>
		</Flex>
	) : (
		<Container {...props}>{content}</Container>
	);
}

const containerStyles = xcss({
	width: '100%',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	paddingBlock: 'space.100',
	flex: '1 1 auto',
});

const wrapperStyles = xcss({
	paddingBlock: 'space.025',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const Container = styled.div({
	display: 'flex',
	flex: '1 1 auto',
	flexDirection: 'row',
	width: '100%',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	paddingTop: token('space.150'),
	paddingBottom: token('space.150'),
});
