import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	emptyFilterResult: {
		id: 'plans.dashboards.main.empty-filter-result',
		defaultMessage: 'No plans were found that match your search',
		description:
			"Message to be displayed when user tried to filter results but their filtering criteria didn't match any",
	},
	headerCaption: {
		id: 'plans.dashboards.main.header-caption',
		defaultMessage: 'Plans',
		description: 'Caption for plans directory page',
	},
	addButtonCaption: {
		id: 'plans.dashboards.main.add-button-caption',
		defaultMessage: 'Create plan',
		description: 'Caption for Create plan button',
	},
	planTitleHeading: {
		id: 'plans.dashboards.main.plan-title-heading',
		defaultMessage: 'Title',
		description: 'Heading for plan title column',
	},
	planLeadHeading: {
		id: 'plans.dashboards.main.plan-lead-heading',
		defaultMessage: 'Lead',
		description: 'Heading for plan lead column',
	},
	planMoreActionsHeading: {
		id: 'plans.dashboards.main.plan-more-actions-heading',
		defaultMessage: 'More actions',
		description: 'Heading for more actions column',
	},
	tableAriaLabel: {
		id: 'plans.dashboards.main.table-aria-label',
		defaultMessage: 'Plans details',
		description:
			'Message to be used as aria-label of plans table. The aria-label is announced by screen readers and helps users who use them understand the purpose of table',
	},
	filterResultsAnnouncement: {
		id: 'plans.dashboards.main.filter-results-announcement',
		defaultMessage:
			'{itemCount, plural, =0 {No plans were found that match your search} one {{itemCount} plan found} other {{itemCount} plans found}}',
		description:
			'Message for screen reader users to announce how many plans were found after filtering',
	},
	planDuplicatedFlag: {
		id: 'plans.dashboards.main.plan-duplicated-flag',
		defaultMessage: 'Your plan was duplicated successfully',
		description: 'Message to be shown in the flag when the plan was duplicated successfully',
	},
	planDuplicatedFlagError: {
		id: 'plans.dashboards.main.plan-duplicated-flag-error',
		defaultMessage: 'There was an error when trying to duplicate your plan',
		description:
			'Message to be shown in the flag when the plan was not duplicated due to an error.',
	},
	planArchivedFlag: {
		id: 'plans.dashboards.main.plan-archived-flag',
		defaultMessage: 'Plan archived successfully',
		description: 'Message to be shown in the flag when the plan was archived successfully',
	},
	planArchivedFlagErrorTitle: {
		id: 'plans.dashboards.main.plan-archived-flag-error-title',
		defaultMessage: 'Something went wrong',
		description: 'Title for error message when plan is archived',
	},
	planArchivedFlagErrorDescription: {
		id: 'plans.dashboards.main.plan-archived-flag-error-description',
		defaultMessage: "We couldn't archive {planTitle}. Try again.",
		description: 'Description for error message when plan is archived',
	},
	planRestoringFlagSuccess: {
		id: 'plans.dashboards.main.plan-restoring-flag-success',
		defaultMessage: 'Plan restored successfully',
		description: 'Title for success message when plan is unarchived',
	},
	planRestoringFlagErrorTitle: {
		id: 'plans.dashboards.main.plan-restoring-flag-error-title',
		defaultMessage: 'Something went wrong',
		description: 'Title for error message when plan is restored',
	},
	planRestoringFlagErrorDescription: {
		id: 'plans.dashboards.main.plan-restoring-flag-error-description',
		defaultMessage: "We can't restore {planTitle}. Try again.",
		description: 'Description for error message when plan is restored',
	},
	archiveSuccessGoToArchiveButtonTitle: {
		id: 'plans.dashboards.main.archive-success-go-to-archive-button-title',
		defaultMessage: 'Go to archive',
		description:
			'Title for the go-to-archive button on the success message after archiving a project.',
	},
	archiveSuccessRestoreButtonTitle: {
		id: 'plans.dashboards.main.archive-success-restore-button-title',
		defaultMessage: 'Restore',
		description: 'Title for the restore button on the success message after archiving a project.',
	},
	planDirectoryNoAccessPermissionTitle: {
		id: 'plans.dashboards.main.plan-directory-no-access-permission-title',
		defaultMessage: "This page isn't available",
		description:
			'This is a header for error flag showing when the user does not have JSW Software access.',
	},
	planDirectoryNoAccessPermissionDescriptionSpork: {
		id: 'plans.dashboards.main.plan-directory-no-access-permission-description-spork',
		defaultMessage:
			'Your Jira permissions have changed, and you can no longer view this page. Contact an administrator to restore access.',
		description:
			'This is a description for error flag showing when the user does not have Jira access.',
	},
	planTrashSuccess: {
		id: 'plans.dashboards.main.plan-trash-success',
		defaultMessage: 'Plan successfully moved to trash',
		description: 'Title of the flag shown when the request for moving a plan to trash succeeded',
	},
	trashSuccessGoToTrash: {
		id: 'plans.dashboards.main.trash-success-go-to-trash',
		defaultMessage: 'Go to trash',
		description: 'Link to trash directory when a plan has been moved to trash',
	},
	trashSuccessRestorePlan: {
		id: 'plans.dashboards.main.trash-success-restore-plan',
		defaultMessage: 'Restore',
		description: 'Link to restore a plan that was moved to trash',
	},
	planTrashErrorTitle: {
		id: 'plans.dashboards.main.plan-trash-error-title',
		defaultMessage: 'Something went wrong',
		description: 'Title of the flag shown when the request for moving a plan to trash failed',
	},
	planTrashErrorDescription: {
		id: 'plans.dashboards.main.plan-trash-error-description',
		defaultMessage: 'We couldn\'t move the "{planTitle}" plan to trash. Try again.',
		description:
			'Message shown in the flag displayed when the request for moving a plan to trash failed',
	},
});
